import { Room } from "../types/HotelType";

export function guestNamePluralizer(guests: Room) {
  return `${cartGuestNamePrularizer(guests.num_adults, guests.num_children, guests.num_infants)}`;
}

export function cartGuestNamePrularizer(adults: number, children: number, infants: number) {
  const adultStr = adults === 1 ? "Adult" : "Adults";
  const childStr = children === 1 ? "Child" : "Children";
  const infantStr = infants === 1 ? "Infant" : "Infants";
  let str = `${adults} ${adultStr}`;
  if (children) str += `, ${children} ${childStr}`;
  if (infants) str += `, ${infants} ${infantStr}`;
  return str;
}

export function hotelNumberPluralizer(num: number) {
  return num === 1 ? `${num} Available Room` : `${num} Available Rooms`;
}
