import { TextApiResponseType, TextType, emptyTextApiReponse } from '../types/TextType'

import { httpRequest } from './httpRequest'

export class TextAPI {

  constructor(private apiBaseUrl: string) { }

  /** Return first text object found for given type, or false if none found. */
  public async getByType(textType: string): Promise<TextType | undefined> {

    try {

      const result = await httpRequest('GET', `${this.apiBaseUrl}/text?types=${textType}`)

      if (result.status === 200) {

        const texts: TextApiResponseType = await result.json()

        return texts.data.length ? texts.data[0] : undefined
      }

    } catch (err) {

      console.warn(err)
    }

    return undefined
  }

  /** Get multiple text objects by type. */
  public async getMultipleByType(textTypes: string[]): Promise<TextType[]> {

    try {

      const result = await httpRequest('GET', `${this.apiBaseUrl}/text?types=${textTypes.join(',')}`)

      if (result.status === 200) {

        const texts: TextApiResponseType = await result.json()

        return texts.data
      }

    } catch (err) {

      console.warn(err)
    }

    return []
  }

}


