import styles from './List.module.scss';

import React from "react";
import ReactHtmlParser from 'react-html-parser';
import Image from "next/legacy/image";

export function List({ title, rating, introText, children, hero_image, expressPassesHotelsInclusions }: { title: string, rating: string, introText: string, children: React.ReactNode, hero_image?:string, expressPassesHotelsInclusions?:React.ReactNode } ) {

  return (
    <div className={ styles.list } id='maincontent'>
      <div className={styles.introArea}>
      { title && <h1>
        <strong>{ title }</strong>
        { rating ? <em className='stars' style={{width : Number(rating) * 20}}>
          <span className='visuallyHidden'>{rating} star hotel</span>
      </em> : null }
        </h1> }
      { expressPassesHotelsInclusions }
      { hero_image && <div className={ styles.hero }><Image priority={ true } src={hero_image} layout='responsive' width='1920' height='384' alt={title}/></div> }
      { introText ? <div className={ styles.intro }>{ ReactHtmlParser(introText) }</div> : null }
      </div>
      { children }
    </div>
  )
}


