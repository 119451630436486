import Link from 'next/link'
import styles from './ContinueLink.module.scss'

import { ticketSections } from '../../Cart/FloatingCart/FloatingCart'

type ContinueLinkProps = {
  currentSection: string,
}

export function ContinueLink({ currentSection }: ContinueLinkProps) {

  const nextStepId = ticketSections.find(s => s.id === currentSection)?.nextSection
  const nextStepPath = nextStepId && ticketSections.find(s => s.id === nextStepId)?.path
  const nextStepLink = nextStepPath ? <Link href={ nextStepPath } passHref className={styles.link}>Continue</Link> : null

  return (
    <>
      { nextStepLink }
    </>
  )
}
