import Link from 'next/link';
import { useState } from 'react';
import Popup from 'reactjs-popup'
import { CartType } from '../../../../types/CartType';
import styles from './FloatingCartPopUp.module.scss'

type FloatingCartPopUpProps = {
  cart: CartType
}

export default function ListHotelPopUp({cart} : FloatingCartPopUpProps) {

  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);

  return (
    <div>
      <button type='button' onClick={() => setOpen(o => !o)}>
        <span className={styles.visuallyHidden}>Number of items in cart:</span>
        {cart.meta.total_count}
      </button>
      <Popup modal open={open} onClose={closeModal} contentStyle={{borderRadius: '10px', width: '30%'}}>
        {(close: Function) => (
          <div className={styles.modal}>
            <p>You have selected fewer rooms than you have searched for. Would you like to proceed?</p>
            <Link passHref href='/shopping-cart' legacyBehavior>
              <button aria-label='go to cart' className={styles.btn}>Go To Cart</button>
            </Link>
          </div>
        )}
      </Popup>
    </div>
  );
}