import { useEffect, useState, useMemo } from 'react'

import styles from './BookingSiteOperated.module.scss'

import ReactHtmlParser from 'react-html-parser'

import { TextAPI } from '../../../api/TextAPI'

const textType = 'booking_site_operated_by'

type BookingSiteOperatedProps = {
  placement: string,
}

export function BookingSiteOperated({placement}: BookingSiteOperatedProps) {

  const [ html, setHtml ] = useState('')

  const textAPI = useMemo(() => new TextAPI(process.env.NEXT_PUBLIC_API_BASE_URL ?? ''), [])

  useEffect(() => {
    textAPI.getByType(textType).then(text => text && setHtml(text.body))
  }, [textAPI])

  let classNames = `${styles.operatedBy}`;
  if (placement == 'intro') {
    classNames = `${styles.operatedBy} ${styles.intro}`;
  } else if (placement == 'ticket') {
    classNames = `${styles.operatedBy} ${styles.ticket}`;
  } else if (placement == 'cart') {
    classNames = `${styles.operatedBy} ${styles.cart}`;
  } else if (placement == 'hotel') {
    classNames = `${styles.operatedBy} ${styles.ticket} ${styles.hotel}`;
  } else if (placement == 'room') {
    classNames = `${styles.operatedBy} ${styles.ticket} ${styles.room}`;
  }

  return html ? (

    <div className={classNames}>
      { ReactHtmlParser(html) }
    </div>

  ) : null

}
